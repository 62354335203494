<template>
    <div>
        <Header/>
        <router-view></router-view>
    </div>
</template>
<script>
import "../assets/cinemaAndDistImages/style/style.css";

import Header from "./CinemaDashboard/partials/Header";
export default {
  name: "cinemahome",
  components: {
    Header
  },
  data() {
    return {};
  }
};
</script>

<style>
</style>
