<template>
  <!-- v-if="['/seat-contact-confirmation','/seat-layouts','/seat-payment-mode','/seat-transaction'].indexOf($route.path) > -1" -->
  <div
    :class="[
      'container-fluid',
      ['/cinemamasters/adminreports'].indexOf($route.path) > -1
        ? 'fixed-topOne'
        : '',
    ]"
  >
    <div class="row">
      <div class="col-md-12 nopadding">
        <nav class="navbar navbar-expand-lg navbar-light cust-nav">
          <router-link class="navbar-brand" to="/cinemamasters/adminreports"
            ><img src="http://api.mytiketi.com/admin/img/mytiketi-logo.png" />
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="CustomNav navbar-nav mr-auto nav-l">
              <li class="nav-item active">
                <router-link class="nav-link" to="/cinemamasters/adminreports">
                  <svg id="house" width="15" height="15" viewBox="0 0 16 16">
                    <path
                      id="Path_99"
                      data-name="Path 99"
                      d="M15.581,5.186l-7-5a1,1,0,0,0-1.162,0l-7,5A1,1,0,0,0,1.581,6.814L2,6.515V15a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1V6.515A1.7,1.7,0,0,0,15,7a1,1,0,0,0,.582-1.814ZM12,14H10V11a2,2,0,0,0-4,0v3H4V5.086L8,2.229l4,2.857Z"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                  </svg>
                  HOME
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/cinemamasters/support">
                  <svg width="15" height="15" viewBox="0 0 18.32 17.32">
                    <g
                      id="iconfinder_headset_172475"
                      transform="translate(0.66 0.32)"
                    >
                      <rect
                        id="Rectangle_570"
                        data-name="Rectangle 570"
                        width="17"
                        height="17"
                        fill="none"
                      />
                      <path
                        id="Path_432"
                        data-name="Path 432"
                        d="M41.36,18.68a.68.68,0,0,0-1.36,0v5.1a.68.68,0,1,0,1.36,0Z"
                        transform="translate(-26.4 -11.88)"
                        fill="none"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <path
                        id="Path_433"
                        data-name="Path 433"
                        d="M23.38,44.36a.68.68,0,0,0,0-1.36h-1.7a.68.68,0,0,0,0,1.36Z"
                        transform="translate(-13.86 -28.38)"
                        fill="none"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <path
                        id="Path_434"
                        data-name="Path 434"
                        d="M8,7.78a5.78,5.78,0,0,1,11.56,0"
                        transform="translate(-5.28 -1.32)"
                        fill="none"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <path
                        id="Path_435"
                        data-name="Path 435"
                        d="M44,20c.939,0,1.7,1.141,1.7,2.55S44.939,25.1,44,25.1"
                        transform="translate(-29.04 -13.2)"
                        fill="none"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <path
                        id="Path_436"
                        data-name="Path 436"
                        d="M6,18.68a.68.68,0,1,1,1.36,0v5.1a.68.68,0,1,1-1.36,0Z"
                        transform="translate(-3.96 -11.88)"
                        fill="none"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <path
                        id="Path_437"
                        data-name="Path 437"
                        d="M2.7,20C1.761,20,1,21.141,1,22.55s.761,2.55,1.7,2.55"
                        transform="translate(-0.66 -13.2)"
                        fill="none"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <path
                        id="Path_438"
                        data-name="Path 438"
                        d="M34.08,37a2.5,2.5,0,0,1-2.72,2.72H30"
                        transform="translate(-19.8 -24.42)"
                        fill="none"
                        stroke="#fff"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                  SUPPORT</router-link
                >
              </li>
              <li class="nav-item">
                <!-- <router-link class="nav-link" to="/cinemamasters/adminreportslist">
                                    <svg width="15" height="15" viewBox="0 0 15.387 18.6">
                                        <g id="g2129" transform="translate(-88.175 -677.74)">
                                            <path id="path1455" d="M94.8,680.268H91.38V678.49H94.8Z" transform="translate(2.779 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            <g id="g1632" transform="translate(88.925 680.268)">
                                                <path id="path1451" d="M102.812,694.645H88.925V679.324h13.887Z" transform="translate(-88.925 -679.324)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="path1457" d="M98.881,680.254H90.129v-.669h8.752Z" transform="translate(-87.562 -679.028)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            </g>
                                            <g id="g1488" transform="translate(91.965 684.223)">
                                                <g id="g1628">
                                                    <path id="path1343-2" d="M90.381,682.484h7.742" transform="translate(-90.317 -679.702)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                    <path id="path1345-2" d="M90.381,681.179h7.742" transform="translate(-90.317 -681.179)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                    <path id="path1347-0" d="M90.381,683.789h7.742" transform="translate(-90.317 -678.224)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                    <path id="path1347-0-7" d="M90.351,685.095h7.742" transform="translate(-90.351 -676.746)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    REPORTS</router-link> -->

                <div class="d-flex align-items-center">
                  <svg width="15" height="15" viewBox="0 0 15.387 18.6">
                    <g id="g2129" transform="translate(-88.175 -677.74)">
                      <path
                        id="path1455"
                        d="M94.8,680.268H91.38V678.49H94.8Z"
                        transform="translate(2.779 0)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <g id="g1632" transform="translate(88.925 680.268)">
                        <path
                          id="path1451"
                          d="M102.812,694.645H88.925V679.324h13.887Z"
                          transform="translate(-88.925 -679.324)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <path
                          id="path1457"
                          d="M98.881,680.254H90.129v-.669h8.752Z"
                          transform="translate(-87.562 -679.028)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </g>
                      <g id="g1488" transform="translate(91.965 684.223)">
                        <g id="g1628">
                          <path
                            id="path1343-2"
                            d="M90.381,682.484h7.742"
                            transform="translate(-90.317 -679.702)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                          <path
                            id="path1345-2"
                            d="M90.381,681.179h7.742"
                            transform="translate(-90.317 -681.179)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                          <path
                            id="path1347-0"
                            d="M90.381,683.789h7.742"
                            transform="translate(-90.317 -678.224)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                          <path
                            id="path1347-0-7"
                            d="M90.351,685.095h7.742"
                            transform="translate(-90.351 -676.746)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <b-dropdown
                    id=""
                    text="REPORTS"
                    class="ml-0 customReportDropDown"
                  >
                    <b-dropdown-item>
                      <router-link to="/cinemamasters/show-reports"
                        >Show Date Report</router-link
                      >
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <router-link to="/cinemamasters/transaction-reports"
                        >Transaction Date Report</router-link
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </li>
            </ul>

            <div class="customDropDown">
              <!-- <img src="../../../assets/cinemaAndDistImages/images/user-icon.png" width="50" height="50" class="rounded-circle"> -->

              <b-dropdown id="ddown1" right :text="userName" class="m-md-2">
                <b-dropdown-item @click="onLogoutAdmin()"
                  >Logout</b-dropdown-item
                >
              </b-dropdown>
              <!-- <button class="logOutCustomClass" >Logout</button> -->
            </div>
            <!--
                        <form class="form-inline my-2 my-lg-0">
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                        </form>
-->
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: "",
    };
  },
  components: {},
  computed: {},
  watch: {},
  beforeCreate() {},
  beforeMount() {
    this.userName = this.secureStore.get("TTHUN")
      ? this.secureStore.get("TTHUN")
      : "TICKETAK";
  },
  mounted: function () {},
  methods: {
    onLogoutAdmin: function () {
      console.log("log out");
      localStorage.clear();
      this.$cookie.delete("UPEM");
      this.$cookie.delete("GOGOHTID");
      this.$router.push("/go-to/login");
    },
  },
  updated() {},
};
</script>

<style>
.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
  background: transparent;
}
#ddown1 {
  color: #fff !important;
}
.customReportDropDown .dropdown-menu {
  min-width: 12rem;
}
.customReportDropDown .btn {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-weight: bold;
}
</style>
<style lang="scss"></style>
<style scoped>
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #fff !important;
  font-weight: 600;
}
svg {
  overflow: hidden;
  vertical-align: inherit;
}

.download a {
  margin: 0 5px;
}

/* .dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff !important;
} */
.logOutCustomClass {
  background: transparent;
  outline: 0;
  color: #fff !important;

  border: 0;
}
</style>
